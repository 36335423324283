<template>
  <div class="elv-transactions-transfer-table-container">
    <el-table
      ref="tableListRef"
      v-loading="props.tableLoading"
      :data="props.tableData.list"
      border
      stripe
      show-summary
      row-key="transactionId"
      :summary-method="setSummaries"
      height="100%"
      class="elv-transactions-table"
      header-cell-class-name="elv-transactions-table-header__cell"
      header-row-class-name="elv-transactions-table-header"
      row-class-name="elv-transactions-table-row"
      cell-class-name="elv-transactions-table-row__cell"
      @row-click="onCellClicked"
      @selection-change="onSelectionChange"
    >
      <el-table-column type="selection" width="40" :disabled="true" reserve-selection />
      <el-table-column width="120" :label="t('common.type')">
        <template #default="{ row }">
          <div class="elv-transactions-table-row__cell-type">
            <SvgIcon :name="typeData(row)?.icon" width="14" height="14" />
            <span> {{ platformType(row.platformType) }}</span>
            <span>-{{ transformI18n(typeData(row)?.text) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="150" :label="t('common.dateTime')">
        <template #default="{ row }">
          <DateTimeCell :params="{ data: row }" />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.Base Asset')" width="120">
        <template #default="{ row }">
          <CurrencyCell :params="{ data: row, value: { cellName: 'Currency' } }" type="transactions" />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('report.Amount (In)')"
        min-width="140"
        label-class-name="elv-transactions-table-row__amount"
        align="right"
        header-align="right"
      >
        <template #default="{ row }">
          <BorrowCell :params="{ data: row, value: { cellName: 'Amount (In)', tableType: 'transactions' } }" />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('report.Amount (Out)')"
        min-width="140"
        label-class-name="elv-transactions-table-row__amount"
        align="right"
        header-align="right"
      >
        <template #default="{ row }">
          <BorrowCell :params="{ data: row, value: { cellName: 'Amount (Out)', tableType: 'transactions' } }" />
        </template>
      </el-table-column>
      <el-table-column :label="`${t('common.account')}/${t('common.counterparty')}`" min-width="485">
        <template #default="{ row }">
          <FromToCell
            :params="{ data: row }"
            :quickCreationCounterparty="true"
            @onViewDetail="onViewCounterpartyDetail(row)"
            @onAddToCounterparty="onAddToCounterparty"
            @onCreateNewCounterparty="onCreateNewCounterparty"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.balanceVerify')" min-width="110">
        <template #default="{ row }">
          <el-tooltip
            :show-arrow="false"
            effect="dark"
            placement="top"
            popper-class="elv-report-table-tips"
            overlay-classname="elv-report-table-tips"
            :show-after="500"
          >
            <div class="elv-transactions-table-row__balance-verify">
              <SvgIcon v-if="row.verifyStatus === 'WAITING'" class="waiting" name="pending" width="16" height="16" />
              <SvgIcon v-else-if="row.verifyStatus === 'FAIL'" name="help-filled-verify" width="16" height="16" />
              <SvgIcon v-else class="success" name="checkmark-filled" width="16" height="16" fill="#80E220" />
            </div>

            <template #content>
              <p v-if="row.verifyStatus === 'WAITING'">{{ t('message.balanceNotVerified') }}</p>
              <p v-else-if="row.verifyStatus === 'FAIL'">
                {{ t('message.balanceVerificationFailed') }}{{ getProvider(row) }}
              </p>
              <p v-else>{{ t('message.balanceVerificationPassed') }}{{ getProvider(row) }}</p>
            </template>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('report.auxiliaryCode')"
        min-width="200"
        class-name="elv-transactions-table-row__auxiliary"
      >
        <template #default="{ row }">
          <template v-if="row?.auxiliaryValueList?.length">
            <div
              v-for="auxiliaryValue in row.auxiliaryValueList"
              :key="auxiliaryValue.auxiliaryTypeId"
              class="elv-transactions-table-row__auxiliary-list"
            >
              <template v-if="auxiliaryValue?.auxiliaryItemList?.length">
                <div
                  v-for="auxiliary in auxiliaryValue.auxiliaryItemList"
                  :key="auxiliary.auxiliaryItemId"
                  class="elv-transactions-table-row__auxiliary-item"
                >
                  {{ auxiliaryValue.auxiliaryType?.name }}: {{ auxiliary?.value }}
                </div>
              </template>
              <template v-else-if="auxiliaryValue?.counterpartyList?.length">
                <div
                  v-for="auxiliary in auxiliaryValue.counterpartyList"
                  :key="auxiliary.counterpartyId"
                  class="elv-transactions-table-row__auxiliary-item"
                >
                  {{ auxiliaryValue.auxiliaryType?.name }}: {{ auxiliary?.name }}
                </div>
              </template>
              <template v-else-if="auxiliaryValue?.entityAccountList?.length">
                <div
                  v-for="auxiliary in auxiliaryValue.entityAccountList"
                  :key="auxiliary.entityAccountId"
                  class="elv-transactions-table-row__auxiliary-item"
                >
                  {{ auxiliaryValue.auxiliaryType?.name }}: {{ auxiliary?.name }}
                </div>
              </template>
            </div>
          </template>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('title.transactionCategory')"
        min-width="200"
        fixed="right"
        label-class-name="el-table-fixed-column--right is-first-column"
      >
        <template #default="{ row }">
          <CategoryCell :params="{ data: row, value: { tableType: 'Transactions' } }" />
        </template>
      </el-table-column>
      <el-table-column label="" width="44" fixed="right" label-class-name="el-table-fixed-column--right">
        <template #default="{ row }">
          <div class="elv-transactions-table-row__operating">
            <Popover
              trigger="hover"
              placement="bottomLeft"
              overlay-class-name="elv-transactions-table-row__operating-popover"
              :offset="2"
            >
              <SvgIcon width="14" height="14" name="more-dian" class="elv-transactions-table-row__operating-icon" />

              <template #content>
                <div class="elv-transactions-table-row__operating-item" @click="onViewDetail(row)">
                  <SvgIcon name="side-panel--close" width="18" height="18" fill="#D0D4D9" />{{ t('button.viewDetail') }}
                </div>
                <template v-if="row?.journalActivityList?.length || row?.reconciliationMatch.reconciliationMatchId">
                  <div
                    class="elv-transactions-table-row__operating-item"
                    :class="{ 'is-disabled': row?.reconciliationMatch.reconciliationMatchId }"
                    @click="onUpdateJournal(row)"
                  >
                    <SvgIcon name="status-change" width="18" height="18" fill="#D0D4D9" />{{ t('button.recategorize') }}
                  </div>
                  <div
                    class="elv-transactions-table-row__operating-item"
                    :class="{ 'is-disabled': row?.reconciliationMatch.reconciliationMatchId }"
                    @click="onRemoveJournal(row)"
                  >
                    <SvgIcon name="common-close" width="18" height="18" fill="#D0D4D9" />{{
                      t('button.removeCategory')
                    }}
                  </div>
                </template>
                <template v-else>
                  <div class="elv-transactions-table-row__operating-item" @click="onCreateJournal(row)">
                    <SvgIcon name="create-project" width="18" height="18" fill="#D0D4D9" />{{
                      t('title.createJournal')
                    }}
                  </div>
                </template>
              </template>
            </Popover>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <elv-pagination
    class="elv-transactions-list-pagination"
    size-popper-class="elv-transactions-pagination__sizes-popper"
    :limit="props.transactionsParams.limit"
    :currentPage="props.transactionsParams.page"
    :total="props.tableData.filteredCount"
    @currentChange="onChangePage"
    @sizeChange="onChangePageSize"
  />
  <TransactionEditOverlay
    ref="transactionEditOverlayRef"
    :isBatch="false"
    :currentData="overlayDrawerData.currentData"
    @onResetList="onResetList"
  />

  <CreateJournalDialog
    ref="createJournalDialogRef"
    :journalTypeList="journalTypeFilterList"
    :isBatch="overlayDrawerData.isBatch"
    :multipleSelection="multipleSelection"
    :currentData="currentData"
    :model="overlayDrawerData.journalModel"
    @onResetList="onResetList"
  />

  <SelectCounterpartyDialog
    ref="selectCounterpartyDialogRef"
    :type="addToCounterpartyType === 'add' ? 'list' : 'edit'"
    :currentData="currentData"
    @onReset="onResetList"
  />

  <CounterpartyDialog
    ref="counterpartyDialogRef"
    tableType="transactions"
    :currentData="currentData"
    model="add"
    @resetList="onResetList"
  />

  <CounterPartyDetailDrawer ref="counterPartyOverlayRef" :drawerData="currentData" @resetList="onResetList" />

  <ElvMessageBox
    ref="deleteJournalMessageBoxRef"
    :confirm-button-text="t('button.delete')"
    :cancel-button-text="t('button.cancel')"
    :title="t('title.deleteJournal')"
    :loading="deleteJournalLoading"
    class="elv-journal-delete-message-box"
    @onConfirmEvent="onConfirmDeleteJournal"
    @onCancelEvent="onCancelDeleteJournal"
  >
    <template #content>
      <span class="elv-confirm-info-header__title">{{
        t('message.deleteJournalInfo', { journalNo: currentData?.journal?.journalNo })
      }}</span></template
    >
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Toolbar from './Toolbar.vue'
import { ElMessage } from 'element-plus'
import { transformI18n } from '@/i18n/index'
import { transactionType } from '@/config/index'
import { map, flatMap, isEmpty } from 'lodash-es'
import type { TableColumnCtx } from 'element-plus'
import TransactionsApi from '@/api/TransactionsApi'
import { notification, Popover } from 'ant-design-vue'
import TransactionEditOverlay from './EditOverlay.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { TransactionItemType } from '#/TransactionsTypes'
import { formatNumber, fieldValueFormat } from '@/lib/utils'
import CreateJournalDialog from './CreateJournalDialog.vue'
import BorrowCell from '@/components/Reports/Cell/BorrowCell.vue'
import FromToCell from '@/components/Reports/Cell/FromToCell.vue'
import { useTransactionStore } from '@/stores/modules/transactions'
import CategoryCell from '@/components/Reports/Cell/CategoryCell.vue'
import DateTimeCell from '@/components/Reports/Cell/DateTimeCell.vue'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'
import CounterpartyDialog from '../../../components/CounterpartyDialog.vue'
import SelectCounterpartyDialog from '../../components/SelectCounterpartyDialog.vue'
import CounterPartyDetailDrawer from '../../../components/CounterPartyDetailDrawer.vue'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  transactionsParams: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
const transactionStore = useTransactionStore()
const emit = defineEmits(['onExport', 'onResetList', 'onChangePage', 'onChangePageSize'])

const tableListRef = ref()
const overlayDrawerRef = ref()
const counterpartyDialogRef = ref()
const selectCounterpartyDialogRef = ref()
const createJournalDialogRef = ref()
const transactionEditOverlayRef = ref()
const counterPartyOverlayRef = ref()
const deleteJournalMessageBoxRef = ref()
const addToCounterpartyType = ref('add')
const deleteJournalLoading = ref(false)
const overlayDrawerData: any = reactive({
  type: 'contact',
  drawerTitle: 'Contact',
  currentData: {},
  isBatch: false,
  journalModel: 'add'
})

const currentData: any = ref({})

interface SummaryMethodProps<T = any> {
  columns: TableColumnCtx<T>[]
  data: T[]
}
const multipleSelection: any = ref([])

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const typeData = computed(() => {
  return (row: TransactionItemType) => {
    let data: any = {}
    const transactionTypeList = flatMap(transactionType, 'list')
    transactionTypeList.forEach((item) => {
      if (item.value === row.type) {
        data = {
          icon: item.icon,
          text: item.label
        }
      }
    })
    return data
  }
})

const platformType = computed(() => {
  return (type: string) => {
    let text = ''
    switch (type) {
      case 'CHAIN':
        text = t('common.onChain')
        break
      case 'EXCHANGE':
        text = t('common.exchange')
        break
      case 'BANK':
        text = t('common.bank')
        break
      case 'CUSTODY':
        text = t('common.custody')
        break
      case 'PAYMENT':
        text = t('common.payment')
        break
      default:
        text = ''
        break
    }
    return text
  }
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const journalTypeFilterList = computed(() => {
  let direction = 'filter'
  if (!isEmpty(currentData.value) && !overlayDrawerData.isBatch) {
    direction = currentData.value?.direction
  } else if (
    overlayDrawerData.isBatch &&
    !isEmpty(multipleSelection.value) &&
    map(multipleSelection.value, 'direction').every((val, i, arr) => val === arr[0])
  ) {
    direction = multipleSelection.value[0]?.direction
  }
  return transactionStore.journalTypeFilterList(direction)
})

const onClearSelected = () => {
  notification.close('elv-transactions-table-toolbar')
  multipleSelection.value = []
  tableListRef.value?.clearSelection()
}

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const getProvider = (row: any) => {
  if (row?.entityAccount.verifyProvider) {
    return `(${row?.entityAccount.verifyProvider?.name})`
  }
  return ''
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const onCreateNewCounterparty = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  counterpartyDialogRef.value?.onCheckCounterpartyDialog()
}

const onViewDetail = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.transaction?.viewDetail
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = row
  overlayDrawerData.currentData = row
  transactionEditOverlayRef.value?.onCheckDrawerStatus()
}

const onViewCounterpartyDetail = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.transaction?.viewDetail
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = row
  overlayDrawerData.currentData = row
  counterPartyOverlayRef.value?.onCheckDrawerStatus()
}

const onCreateJournal = (row: any, createBatch: boolean = false) => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.journal?.create) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = row
  overlayDrawerData.isBatch = createBatch
  overlayDrawerData.journalModel = 'add'
  createJournalDialogRef.value?.onCheckCreateJournalDialog()
}

const onBatchCreateJournal = () => {
  onCreateJournal({}, true)
  notification.close('elv-transactions-table-toolbar')
}

const onAddToCounterparty = (type: string) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  addToCounterpartyType.value = type
  selectCounterpartyDialogRef.value?.onCheckSelectCounterpartyDialog()
}

const onResetList = () => {
  multipleSelection.value = []
  tableListRef.value?.clearSelection()
  emit('onResetList')
}

const onSelectionChange = (val: any[]) => {
  multipleSelection.value = val
  overlayDrawerData.isBatch = true
  if (val.length) {
    notification.open({
      key: 'elv-transactions-table-toolbar',
      message: '',
      description: () =>
        h(Toolbar, {
          selectedTotal: val?.length,
          multipleSelection: multipleSelection.value,
          onClearSelected,
          onBatchCreateJournal,
          onExportTransactions: () => emit('onExport', multipleSelection.value)
        }),
      class: 'elv-transactions-table-toolbar elv-table-toolbar',
      duration: 0,
      placement: 'bottom'
    })
  } else {
    notification.close('elv-transactions-table-toolbar')
  }
}

// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: any, column: any, e: any) => {
  try {
    console.log(row, column)
    overlayDrawerData.currentData = {}
    currentData.value = {}
    if (column?.no === 6) {
      const direction = e.target?.attributes['data-type']?.value
      switch (direction) {
        case 'from':
          if (row?.fromEntityAccount?.address) {
            overlayDrawerData.drawerTitle = 'Account'
            overlayDrawerData.currentData = {
              value: row?.fromAddress,
              source: { ...row?.source },
              platform: { ...row?.fromEntityAccount?.platform },
              ...row?.fromEntityAccount
            }
          } else if (row?.fromAddressContact?.contactId) {
            overlayDrawerData.drawerTitle = 'Contact'
            overlayDrawerData.currentData = {
              value: row?.fromAddress,
              source: { ...row?.source },
              counterparty: row?.counterparty ?? {},
              ...row?.fromAddressContact
            }
          } else {
            switch (row?.direction) {
              case 'OUT':
                overlayDrawerData.drawerTitle = 'Account'
                overlayDrawerData.currentData = {
                  value: row?.fromAddress,
                  source: { ...row?.source }
                }
                break
              default:
                overlayDrawerData.drawerTitle = 'Contact'
                overlayDrawerData.currentData = {
                  value: row?.fromAddress,
                  counterparty: row?.counterparty ?? {},
                  source: { ...row?.source }
                }
                break
            }
          }
          break
        default:
          if (row?.toEntityAccount?.address) {
            overlayDrawerData.drawerTitle = 'Account'
            overlayDrawerData.currentData = {
              value: row?.toAddress,
              source: { ...row?.source },
              platform: { ...row?.toEntityAccount?.platform },
              ...row?.toEntityAccount
            }
          } else if (row?.toAddressContact?.role) {
            overlayDrawerData.drawerTitle = 'Contact'
            overlayDrawerData.currentData = {
              value: row?.toAddress,
              source: { ...row?.source },
              counterparty: row?.counterparty ?? {},
              ...row?.toAddressContact
            }
          } else {
            switch (row?.direction) {
              case 'IN':
                overlayDrawerData.drawerTitle = 'Account'
                overlayDrawerData.currentData = {
                  value: row?.toAddress,
                  source: { ...row?.source }
                }
                break
              default:
                overlayDrawerData.drawerTitle = 'Contact'
                overlayDrawerData.currentData = {
                  value: row?.toAddress,
                  counterparty: row?.counterparty ?? {},
                  source: { ...row?.source }
                }
                break
            }
          }
          break
      }
      overlayDrawerData.isBatch = false
      overlayDrawerData.type = 'transaction'
      currentData.value = row
      overlayDrawerRef.value?.onCheckDrawerStatus()
    } else if (
      column?.no !== 9 &&
      column?.no !== 10 &&
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__copy' &&
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__txHash'
    ) {
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.transaction?.viewDetail
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      overlayDrawerData.currentData = row
      transactionEditOverlayRef.value?.onCheckDrawerStatus()
    } else if (e.target?.className === 'elv-financials-cell-category__none') {
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.journal?.create
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      currentData.value = row
      onCreateJournal(row)
    }
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  }
}

const onConfirmDeleteJournal = async () => {
  try {
    deleteJournalLoading.value = true
    await TransactionsApi.deleteJournal(entityId.value, currentData.value?.journal?.journalActivityId)
    ElMessage.success(t('message.deleteJournalSuccess'))
    emit('onResetList')
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    deleteJournalLoading.value = false
    deleteJournalMessageBoxRef.value?.onCheckMessageBoxDialog()
  }
}

const onCancelDeleteJournal = () => {
  deleteJournalMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onRemoveJournal = (row: any) => {
  if (row?.reconciliationMatch.reconciliationMatchId) return
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.journal?.delete) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = row
  currentData.value.journal = row?.journalActivityList?.[0]
  deleteJournalMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onUpdateJournal = (row: any) => {
  if (row?.reconciliationMatch.reconciliationMatchId) return
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.journal?.update) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = row
  currentData.value.journal = row?.journalActivityList?.[0]
  overlayDrawerData.isBatch = false
  overlayDrawerData.journalModel = 'edit'
  createJournalDialogRef.value?.onCheckCreateJournalDialog()
}

// eslint-disable-next-line no-unused-vars
const onUpdateAccountOrContact = (data: any) => {
  overlayDrawerData.currentData = data
}

const setSummaries = (param: SummaryMethodProps) => {
  const { columns } = param
  const sums: string[] = []
  columns.forEach((column, index) => {
    if (index === 1) {
      sums[index] = `${t('common.total')}: ${formatNumber(props.tableData.filteredCount)}`
      return
    }
    if (index > 6 || index === 2) {
      // eslint-disable-next-line no-param-reassign
      column.rowSpan = 0
      // eslint-disable-next-line no-param-reassign
      column.colSpan = 0
    }
    if (index === 4) {
      sums[index] = `${fieldValueFormat(
        props.tableData.total?.amountInFC,
        {
          dollar: true,
          symbol: `${
            entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
          }`
        },
        'NUMBER'
      )}`
    }
    if (index === 5) {
      sums[index] = `${fieldValueFormat(
        props.tableData.total?.amountOutFC,
        {
          dollar: true,
          symbol: `${
            entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
          }`
        },
        'NUMBER'
      )}`
    }
  })

  nextTick(() => {
    setTimeout(() => {
      const current = tableListRef.value.$el
        .querySelector('.el-table__footer-wrapper')
        .querySelector('.el-table__footer')
      const cell = current.rows[0].cells
      cell[2].style.display = 'none'
      // cell[2].style.display = 'none'
      // cell[3].style.display = 'none'
      cell[1].colSpan = '2'
      cell[1].firstChild.className = 'cell elv-transactions-table-row__total'
      cell[4].style.textAlign = 'right'
      cell[5].style.textAlign = 'right'
      if (cell[4].firstChild.childNodes.length === 1) {
        const span = document.createElement('p')
        span.innerText = `${t('common.sum')}`
        cell[4].firstChild.appendChild(span)
      }
      if (cell[5].firstChild.childNodes.length === 1) {
        const span = document.createElement('p')
        span.innerText = `${t('common.sum')}`
        cell[5].firstChild.appendChild(span)
      }
    }, 10)
  })
  return sums
}

defineExpose({
  ref: tableListRef
})

onBeforeUnmount(() => {
  notification.destroy()
  multipleSelection.value = []
  tableListRef.value?.clearSelection()
})
</script>

<style lang="scss">
.elv-transactions-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;
    height: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-transactions-table-header {
    background: #eef4fb;

    .elv-transactions-table-header__cell {
      background: #eef4fb !important;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &:last-child {
        border-right: 0px;

        &.el-table-fixed-column--right {
          right: 0;
        }
      }

      &:nth-last-child(2) {
        &.el-table-fixed-column--right {
          right: 44px;
        }
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-transactions-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    &.is-right .cell {
      text-align: right;
    }

    &.is-center .cell {
      text-align: center;
    }

    .elv-transactions-table-row__cell-activity {
      font-weight: 500;
    }

    .elv-transactions-table-row__cell-type {
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      svg {
        overflow: visible;
        margin-right: 4px;
      }
    }

    .elv-transactions-table-row__balance-verify {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;
      }

      img {
        width: 16px;
        height: 16px;
        display: block;
      }

      .waiting {
        fill: #feb69c;
      }
    }

    &.elv-transactions-table-row__auxiliary .cell {
      display: flex;
      align-items: center;
      gap: 4px;

      .elv-transactions-table-row__auxiliary-list,
      .elv-transactions-table-row__auxiliary-item {
        display: flex;
        align-items: center;
      }

      .elv-transactions-table-row__auxiliary-list {
        gap: 4px;
      }

      .elv-transactions-table-row__auxiliary-item {
        box-sizing: border-box;
        padding: 4px 12px;
        justify-content: center;
        white-space: nowrap;
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 12px;
        border: 1px solid #edf0f3;
        background: #f9fafb;
      }
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }

    .elv-transactions-table-row__operating {
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      .elv-transactions-table-row__operating-icon {
        fill: #838d95;
        cursor: pointer;
      }

      .el-tooltip__trigger {
        color: #2f63eb;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        cursor: pointer;
        align-items: center;
      }
    }
  }

  .el-table__footer-wrapper {
    .el-table__footer {
      height: 42px;

      .cell.elv-transactions-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-transactions-table-row__amount {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        p {
          color: #838d95;
          font-family: 'Barlow';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: none;
        }
      }
    }
  }
}

.elv-transactions-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}

.elv-journal-delete-message-box.el-dialog {
  .el-dialog__body {
    padding: 23px 24px 26px;

    span {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.ant-popover.elv-transactions-table-row__operating-popover {
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  background: #fff;
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.3),
    0px 2px 6px 0px rgba(0, 0, 0, 0.05);

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    padding: 0px;
    box-shadow: none;
    width: 161px;
    border-radius: 6px;
  }

  .elv-transactions-table-row__operating-item {
    display: flex;
    height: 32px;
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
    align-items: center;
    align-self: stretch;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: background 0.1s;

    svg {
      margin-right: 8px;
    }

    &.is-disabled {
      color: #aaafb6;
      cursor: not-allowed;
    }

    &:hover {
      background: #f9fafb;
    }
  }
}
</style>
<style lang="scss" scoped>
.elv-transactions-transfer-table-container {
  height: calc(100% - 54px);
  position: relative;
}
</style>
