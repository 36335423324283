<template>
  <div>
    <Header
      ref="transactionsHeaderRef"
      type="TRANSACTIONS"
      :title="t('project.nav.transactions')"
      updated-on=""
      :show-regenerate="false"
      :show-regenerate-button="true"
    />

    <HeaderTabs :journals-total="formatNumber(transactionStore.journalList?.total)" />

    <TransactionScreening
      ref="transactionScreeningRef"
      table-type="transaction"
      :export-params="exportParams"
      @onChangeSort="onChangeSort"
      @onFilterData="onFilterData"
    />
  </div>

  <div class="elv-transactions-transfer-table-list" :style="{ height: tableHeight }">
    <list-table
      ref="listTableRef"
      :transactions-params="transactionsParams"
      :table-height="tableHeight"
      :table-data="transactionsData"
      :table-loading="tableLoading"
      @on-change-page="onChangePage"
      @onExport="onExportTransactionList"
      @onResetList="getTransactionsTableData"
      @on-change-page-size="onChangePageSize"
    />
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import { formatNumber } from '@/lib/utils'
import Header from '../../components/Header.vue'
import ListTable from './components/ListTable.vue'
import { map, cloneDeep, isEmpty } from 'lodash-es'
import HeaderTabs from '../components/HeaderTabs.vue'
import TransactionScreening from '../components/ListScreening.vue'
import { useTransactionStore } from '@/stores/modules/transactions'
import { transactionFilterSaveType } from '@/config/transactions'

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const transactionStore = useTransactionStore()

const listTableRef = ref()
const transactionsHeaderRef = ref()
const transactionScreeningRef = ref()
const loading = ref(false)
const tableLoading = ref(true)

const transactionsParams = ref({
  page: 1,
  limit: 20,
  sortField: 'datetime',
  sortDirection: 'DESC'
})

const transactionsData: any = ref({
  total: {
    amountInFC: 0,
    amountOutFC: 0
  },
  totalCount: 0,
  list: []
})

const tableHeight = computed(() => {
  return transactionsHeaderRef.value?.isShowRegeneratedInfo ? `calc(100% - 186px)` : `calc(100% - 132px)`
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const exportParams = computed(() => {
  const params: any = {
    type: 'TRANSACTION',
    extra: {
      sortField: 'datetime',
      sortDirection: transactionsParams.value.sortDirection
    }
  }

  const currentFilterData = transactionStore.entityFilterList.find((item: any) => {
    return item.type === transactionFilterSaveType.TRANSFER
  })
  const currentSaveList = currentFilterData?.list || []
  const entityFilter = currentSaveList.find((item: any) => {
    return item.entityId === entityId.value
  })
  if (!isEmpty(entityFilter)) {
    params.extra = {
      ...params.extra,
      ...entityFilter.data
    }
    if (entityFilter.data?.currency?.length) {
      params.extra.currency = entityFilter.data?.currency.map((item: string) => {
        return item.split('__')[1] ?? item
      })
    }
  }
  return params
})

const getTransactionsTableData = async () => {
  try {
    tableLoading.value = true
    let params: Record<string, unknown> = {
      ...transactionsParams.value
    }
    const currentFilterData = transactionStore.entityFilterList.find((item: any) => {
      return item.type === transactionFilterSaveType.TRANSFER
    })
    const currentSaveList = currentFilterData?.list || []
    const entityFilter = currentSaveList.find((item: any) => {
      return item.entityId === entityId.value
    })
    if (!isEmpty(entityFilter)) {
      params = {
        ...params,
        ...entityFilter.data
      }
      if (entityFilter.data?.currency?.length) {
        params.currency = entityFilter.data?.currency.map((item: string) => {
          return item.split('__')[1] ?? item
        })
      }
      if (entityFilter.data?.journalTypeIds?.length) {
        params.journalTypeIds = entityFilter.data?.journalTypeIds.filter((item: string) => {
          return (
            item === '0' ||
            transactionStore.journalTypeList.find((journalType: any) => {
              return journalType.journalTypeId === item
            })
          )
        })
        const filterData: any = cloneDeep(entityFilter.data)
        filterData.journalTypeIds = params.journalTypeIds
        transactionStore.editEntityFilter(entityId.value, filterData, transactionFilterSaveType.TRANSFER)
      }
    }
    const response = await transactionStore.fetchTransactionList(entityId.value, params)
    listTableRef.value?.ref?.setScrollTop(0)
    transactionsData.value = response
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

const onExportTransactionList = async (exportList: any[]) => {
  const params = {
    type: 'TRANSACTION',
    extra: {
      transactionIds: map(exportList, 'transactionId')
    }
  }
  transactionScreeningRef.value?.exportButtonRef?.onExport(params)
}

/**
 * @description: 更改每页显示数量
 * @param {number} limit 每页显示数量
 */
const onChangePageSize = (limit: number) => {
  transactionsParams.value.limit = limit
  transactionsParams.value.page = 1
  getTransactionsTableData()
}

const onChangePage = (page: number) => {
  transactionsParams.value.page = page
  getTransactionsTableData()
}

const onChangeSort = (sort: string) => {
  transactionsParams.value.sortDirection = sort
  transactionsParams.value.page = 1
  getTransactionsTableData()
}
const onFilterData = () => {
  transactionsParams.value.page = 1
  getTransactionsTableData()
}

watch(
  () => route,
  async (newValue) => {
    try {
      if (newValue.name === 'entity-transactions-list') {
        loading.value = true
        transactionStore.transactionFilterDataInit(entityId.value, 'Transfer')
        await getTransactionsTableData()
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  },
  { immediate: true }
)
</script>
<style lang="scss" scoped>
.elv-transactions-transfer-table-list {
  position: relative;
}
</style>
