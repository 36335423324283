<template>
  <el-drawer
    v-model="isShowEditDrawer"
    :show-close="false"
    modal-class="elv-report-transaction-overlay"
    class="elv-report-transaction-drawer"
    :before-close="onCheckDrawerStatus"
    :size="400"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-report-transaction-drawer-header__title">
        {{ t('report.transfer') }}
      </h4>
      <SvgIcon
        name="close"
        width="24"
        height="24"
        class="elv-report-transaction-drawer-header__close"
        @click="onCheckDrawerStatus"
      />
    </template>
    <el-scrollbar v-loading="loading">
      <div class="elv-report-transaction-drawer-info">
        <p class="elv-report-transaction-drawer-info__title">
          {{ platformType }}<span /> {{ transformI18n(direction) }}
        </p>
        <h3
          class="elv-report-transaction-drawer-info__total"
          :title="`${props.currentData?.amount}${props.currentData?.currency}`"
        >
          {{ formatNumber(props.currentData?.amount, 2) }}
          {{ props.currentData?.underlyingCurrency?.showSymbol ?? props.currentData?.currency }}
          <div
            v-if="props.currentData?.extraData?.processingAmount && props.currentData?.extraData?.processingCurrency"
            class="elv-report-transaction-drawer-info__holding"
          >
            {{ t('report.processing') }}
            <p>
              {{ props.currentData?.extraData?.processingAmount }}
              {{ props.currentData?.extraData?.processingCurrency }}
            </p>
          </div>
        </h3>
        <div
          class="elv-report-transaction-drawer-info__balance-verify"
          :class="{
            'is-success': props.currentData?.verifyStatus === 'SUCCESS',
            'is-fail': props.currentData?.verifyStatus === 'FAIL'
          }"
        >
          <template v-if="props.currentData?.verifyStatus === 'WAITING'">
            <SvgIcon class="waiting" name="pending" width="12" height="12" fill="#FEB69C" />{{
              t('message.balanceNotVerified')
            }}
          </template>
          <template v-else-if="props.currentData?.verifyStatus === 'FAIL'">
            <SvgIcon name="help-filled-verify" width="12" height="12" />
            {{ t('message.balanceVerificationFailed') }}{{ getProvider(props.currentData) }}
          </template>
          <template v-else>
            <SvgIcon class="success" name="checkmark-filled" width="12" height="12" fill="#80E220" />{{
              t('message.balanceVerificationPassed')
            }}{{ getProvider(props.currentData) }}
          </template>
        </div>
        <ul>
          <li class="elv-report-transaction-drawer-info__from">
            <span class="elv-report-transaction-drawer-info__from-title"
              >{{
                props.currentData?.direction !== 'IN' && props.currentData?.entityAccount?.name
                  ? t('report.Account')
                  : t('common.counterparty')
              }}
              ({{ t('report.from') }})<SvgIcon
                v-if="
                  !(props.currentData?.direction !== 'IN' && props.currentData?.entityAccount?.name) &&
                  !isFeeType &&
                  !isFXType &&
                  !isExchangeTrade &&
                  !props.currentData?.internalEntityAccount?.name &&
                  (props.currentData?.contactIdentity || transactionDetail?.counterparty?.name)
                "
                name="sources-edit"
                width="12"
                height="12"
                @click="onEditCounterparty"
              />
            </span>
            <div
              v-if="props.currentData?.entityAccount?.name && props.currentData?.direction === 'OUT'"
              class="elv-report-transaction-drawer-info__from-account"
            >
              <SvgIcon v-if="currentFromLogo === 'csv'" name="sources-csv" width="14" height="14" class="from_logo" />
              <img
                v-else-if="currentFromLogo !== ''"
                :src="currentFromLogo"
                :alt="entityStore.entityDetail?.name"
                :onerror="useDefaultImage"
              />
              <img
                v-else
                :onerror="useDefaultImage"
                src="@/assets/img/default-logo.png"
                :alt="entityStore.entityDetail?.name"
              />
              <template v-if="props.currentData?.entityAccount?.name">
                <span :title="props.currentData?.entityAccount?.name">{{
                  props.currentData?.entityAccount?.name
                }}</span>
                <span class="elv-report-transaction-drawer-info__you">YOU</span>
              </template>
            </div>
            <div
              v-else-if="
                ((transactionDetail?.counterpartyId && transactionDetail?.counterparty?.name) || hasInputs) &&
                !isFXType &&
                props.currentData?.direction === 'IN'
              "
              class="elv-report-transaction-drawer-info__from-contact"
            >
              <img
                v-if="currentFromLogo !== ''"
                :src="currentFromLogo"
                :alt="entityStore.entityDetail?.name"
                :onerror="useDefaultImage"
              />
              <img
                v-else
                :onerror="useDefaultImage"
                src="@/assets/img/default-logo.png"
                :alt="entityStore.entityDetail?.name"
              />
              <span v-if="transactionDetail?.counterparty?.name || !hasInputs">{{
                transactionDetail?.counterparty?.name
              }}</span>

              <p v-else class="elv-report-transaction-drawer-info__multi-addresses">
                <span>Multi Addresses</span
                ><span class="elv-report-transaction-drawer-info__fee">{{ transactionDetail?.inputs?.length }}</span>
              </p>
            </div>
            <div
              v-else-if="
                (!transactionDetail?.counterparty?.name || transactionDetail?.internalEntityAccount?.name) &&
                props.currentData?.direction === 'IN' &&
                !isFXType &&
                props.currentData?.contactIdentity
              "
              class="elv-report-transaction-drawer-info__from-account"
            >
              <SvgIcon v-if="currentFromLogo === 'csv'" name="sources-csv" width="14" height="14" class="from_logo" />
              <img
                v-else-if="currentFromLogo !== ''"
                :src="currentFromLogo"
                :alt="entityStore.entityDetail?.name"
                :onerror="useDefaultImage"
              />
              <img v-else-if="currentFromLogo === ''" src="@/assets/img/default-counterparty-logo.png" alt="logo" />
              <img
                v-else
                :onerror="useDefaultImage"
                src="@/assets/img/default-logo.png"
                :alt="entityStore.entityDetail?.name"
              />
              <template v-if="props.currentData?.internalEntityAccount?.name">
                <span :title="props.currentData?.internalEntityAccount?.name">{{
                  props.currentData?.internalEntityAccount?.name
                }}</span>
                <span class="elv-report-transaction-drawer-info__you">YOU</span>
              </template>
              <p
                v-else
                class="elv-report-transaction-drawer-info__from-address"
                :data-clipboard-text="props.currentData?.contactIdentity"
              >
                {{ formatInputAddress(props.currentData?.contactIdentity) }}
                <SvgIcon
                  name="sources-copy"
                  width="12"
                  height="12"
                  @click="onClickedCopy(props.currentData?.contactIdentity)"
                />
              </p>
            </div>
            <div
              v-else-if="
                props.currentData?.direction === 'IN' &&
                (isExchangeTrade || isFXType || props.currentData?.type === 'EXCHANGE_FUNDING_FEE')
              "
              class="elv-report-transaction-drawer-info__from-account"
            >
              <img
                v-if="currentFromLogo !== ''"
                :src="currentFromLogo"
                :alt="entityStore.entityDetail?.name"
                :onerror="useDefaultImage"
              />
              <img v-else-if="currentFromLogo === ''" src="@/assets/img/default-counterparty-logo.png" alt="logo" />
              <img
                v-else
                :onerror="useDefaultImage"
                src="@/assets/img/default-logo.png"
                :alt="entityStore.entityDetail?.name"
              />

              <span :title="props.currentData?.platform?.name">{{ props.currentData?.platform?.name }}</span>
              <span
                v-if="props.currentData?.type !== 'EXCHANGE_FUNDING_FEE'"
                class="elv-report-transaction-drawer-info__trade"
                >{{ isExchangeTrade ? 'TRADE' : 'TX' }}</span
              >
            </div>
            <div v-else class="elv-report-transaction-drawer-info__from-account">-</div>
          </li>
          <li class="elv-report-transaction-drawer-info__to">
            <span class="elv-report-transaction-drawer-info__to-title"
              >{{
                props.currentData?.direction !== 'OUT' && props.currentData?.entityAccount?.name
                  ? t('report.Account')
                  : t('common.counterparty')
              }}
              ({{ t('report.to') }})<SvgIcon
                v-if="
                  !(props.currentData?.direction !== 'OUT' && props.currentData?.entityAccount?.name) &&
                  !isFeeType &&
                  !isFXType &&
                  !isExchangeTrade &&
                  !props.currentData?.internalEntityAccount?.name &&
                  (props.currentData?.contactIdentity || transactionDetail?.counterparty?.name)
                "
                name="sources-edit"
                width="12"
                height="12"
                @click="onEditCounterparty"
            /></span>
            <div
              v-if="
                (props.currentData?.direction === 'OUT' &&
                  !transactionDetail?.counterparty?.name &&
                  props.currentData?.contactIdentity) ||
                isFeeType ||
                isFXType ||
                isExchangeTrade
              "
              class="elv-report-transaction-drawer-info__to-account"
            >
              <SvgIcon v-if="currentToLogo === 'csv'" name="sources-csv" width="14" height="14" class="to_logo" />
              <img v-else-if="currentToLogo !== ''" :src="currentToLogo" alt="logo" :onerror="useDefaultImage" />
              <img v-else-if="currentToLogo === ''" src="@/assets/img/default-counterparty-logo.png" alt="logo" />
              <img
                v-else
                :onerror="useDefaultImage"
                src="@/assets/img/default-logo.png"
                :alt="entityStore.entityDetail?.name"
              />
              <template v-if="props.currentData?.direction === 'IN' && props.currentData?.entityAccount?.name">
                <span :title="props.currentData?.entityAccount?.name">{{
                  props.currentData?.entityAccount?.name
                }}</span>
                <span class="elv-report-transaction-drawer-info__you">YOU</span>
              </template>
              <template
                v-if="
                  props.currentData?.direction === 'OUT' && props.currentData?.internalEntityAccount?.name && !isFeeType
                "
              >
                <span :title="props.currentData?.internalEntityAccount?.name">{{
                  props.currentData?.internalEntityAccount?.name
                }}</span>
                <span class="elv-report-transaction-drawer-info__you">YOU</span>
              </template>
              <p
                v-if="
                  !transactionDetail?.counterparty?.name &&
                  !isFeeType &&
                  !isFXType &&
                  !isExchangeTrade &&
                  !props.currentData?.internalEntityAccount?.name &&
                  props.currentData?.contactIdentity &&
                  props.currentData?.direction === 'OUT'
                "
                class="elv-report-transaction-drawer-info__to-address"
                :data-clipboard-text="props.currentData?.contactIdentity"
              >
                {{ formatInputAddress(props.currentData?.contactIdentity) }}
                <SvgIcon
                  name="sources-copy"
                  width="12"
                  height="12"
                  @click="onClickedCopy(props.currentData?.contactIdentity)"
                />
              </p>
              <p
                v-if="
                  isFeeType &&
                  ((props.currentData?.type === 'EXCHANGE_FUNDING_FEE' && props.currentData.direction !== 'IN') ||
                    props.currentData?.type !== 'EXCHANGE_FUNDING_FEE')
                "
              >
                <span>{{ props.currentData?.platform?.name }}</span
                ><span class="elv-report-transaction-drawer-info__fee">FEE</span>
              </p>
              <p
                v-if="
                  (isExchangeTrade || isFXType) &&
                  !props.currentData?.contactIdentity &&
                  !transactionDetail?.counterparty?.name &&
                  props.currentData?.direction === 'OUT'
                "
              >
                <span>{{ props.currentData?.platform?.name }}</span
                ><span class="elv-report-transaction-drawer-info__fee">{{ isExchangeTrade ? 'TRADE' : 'FX' }}</span>
              </p>
            </div>
            <div
              v-else-if="
                props.currentData?.direction === 'OUT' &&
                transactionDetail?.counterpartyId &&
                transactionDetail?.counterparty?.name &&
                !isFeeType
              "
              class="elv-report-transaction-drawer-info__to-contact"
            >
              <img v-if="currentToLogo !== ''" :src="currentToLogo" alt="logo" :onerror="useDefaultImage" />
              <img v-else-if="currentToLogo === ''" src="@/assets/img/default-counterparty-logo.png" alt="logo" />
              <img
                v-else
                :onerror="useDefaultImage"
                src="@/assets/img/default-logo.png"
                :alt="entityStore.entityDetail?.name"
              />
              <span>{{ transactionDetail?.counterparty?.name }} </span>
            </div>
            <div
              v-else-if="props.currentData?.direction === 'IN' && props.currentData?.entityAccount?.name"
              class="elv-report-transaction-drawer-info__to-account"
            >
              <SvgIcon v-if="currentToLogo === 'csv'" name="sources-csv" width="14" height="14" class="to_logo" />
              <img v-else-if="currentToLogo !== ''" :src="currentToLogo" alt="logo" :onerror="useDefaultImage" />
              <img
                v-else
                :onerror="useDefaultImage"
                src="@/assets/img/default-logo.png"
                :alt="entityStore.entityDetail?.name"
              />

              <span :title="props.currentData?.entityAccount?.name">{{ props.currentData?.entityAccount?.name }}</span>
              <span class="elv-report-transaction-drawer-info__you">YOU</span>
            </div>
            <div v-else class="elv-report-transaction-drawer-info__to-contact">-</div>
          </li>
          <li class="elv-report-transaction-drawer-info__base_asset">
            <span>{{ t('report.Base Asset') }}</span>
            <p>
              <CurrencyCell
                :params="{ data: props.currentData, value: { cellName: 'Currency' } }"
                type="transactions"
              />
            </p>
          </li>
          <li class="elv-report-transaction-drawer-info__amount-type">
            <span>{{ t('report.Amount (In)') }}</span>
            <BorrowCell
              :params="{ data: props.currentData, value: { cellName: 'Amount (In)', tableType: 'transactions' } }"
            />
          </li>
          <li class="elv-report-transaction-drawer-info__amount-type">
            <span>{{ t('report.Amount (Out)') }}</span>
            <BorrowCell
              :params="{ data: props.currentData, value: { cellName: 'Amount (Out)', tableType: 'transactions' } }"
            />
          </li>
          <li class="elv-report-transaction-drawer-info__source">
            <span>{{ t('report.source') }}</span>
            <p>
              <template v-if="props.currentData?.type !== 'GAIN_LOSS'">
                <span>{{ transactionDetail?.source?.name ?? props.currentData?.source?.name }}</span>
              </template>
              <template v-else>-</template>
            </p>
          </li>
          <li class="elv-report-transaction-drawer-transaction__detail">
            <span>{{ t('report.transferId') }}</span>
            <div>
              <SvgIcon v-if="SourceLogo === 'csv'" name="sources-csv" width="14" height="14" />
              <SvgIcon
                v-else-if="
                  props.currentData?.platformType === 'CHAIN' &&
                  props.currentData?.txHash &&
                  transactionDetailIcon.includes(props.currentData?.source.platform?.slug)
                "
                :name="`chain-${props.currentData?.source.platform?.slug}`"
                width="14"
                height="14"
              />
              <img v-else-if="props.currentData?.txHash" :src="SourceLogo?.logo" :alt="SourceLogo?.name" />
              <p>{{ formatInputAddress(props.currentData?.txHash) }}</p>
              <SvgIcon
                v-if="props.currentData?.platformType === 'CHAIN' && props.currentData?.txHash"
                name="transaction-launch"
                width="12"
                height="12"
                @click="onOpenBrowser"
              />
            </div>
          </li>
          <li class="elv-report-transaction-drawer-info__dateTime">
            <span>{{ t('report.datetime') }}</span>

            <el-tooltip
              :show-arrow="false"
              effect="dark"
              placement="top"
              popper-class="elv-report-table-tips"
              overlay-classname="elv-report-table-tips"
              :show-after="500"
            >
              <p>
                {{
                  dayjs(props.currentData?.datetime)
                    .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                    .format('YYYY/MM/DD HH:mm')
                }}
              </p>
              <template #content>
                <div>
                  <p>
                    {{ t('project.originalDatetime')
                    }}{{
                      calculateUtcOffset(props.currentData?.datetime, props.currentData?.source?.timezone ?? 'UTC')
                    }}:
                    {{
                      dayjs(props.currentData?.datetime)
                        .tz(props.currentData?.source?.timezone ?? 'UTC')
                        .format('YYYY/MM/DD HH:mm')
                    }}
                  </p>
                  <p>
                    {{ t('project.utcDatetime') }}:
                    {{ dayjs.tz(props.currentData?.datetime, 'UTC').format('YYYY/MM/DD HH:mm') }}
                  </p>
                  <p>
                    {{ t('project.reportingDatetime')
                    }}{{
                      calculateUtcOffset(props.currentData?.datetime, entityStore.entityDetail?.timezone ?? 'UTC')
                    }}:
                    {{
                      dayjs(props.currentData?.datetime)
                        .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                        .format('YYYY/MM/DD HH:mm')
                    }}
                  </p>
                </div>
              </template>
            </el-tooltip>
          </li>
          <li class="elv-report-transaction-drawer-info__amount">
            <template v-if="props.currentData?.extraData?.pair && props.currentData?.extraData?.price">
              <span>{{ t('common.price') }} ({{ props.currentData?.extraData?.pair }})</span>
              <p :title="`${props.currentData?.extraData?.price}`">
                {{ formatNumber(props.currentData?.extraData?.price, 3) }}
              </p>
            </template>
          </li>
        </ul>
        <div v-if="transactionDetail?.journalActivityList?.length" class="elv-report-transaction-drawer-journal">
          <p class="elv-report-transaction-drawer-journal-title">{{ t('report.relatedJournal') }}</p>
          <div class="elv-report-transaction-drawer-journal-list">
            <div v-for="(journalActivity, index) in transactionDetail?.journalActivityList" :key="index">
              <div class="elv-report-transaction-drawer-journal-item__type">
                <p>{{ journalActivity?.journalType?.name }}</p>
                <span>{{ journalActivity?.journalNo }}</span>
              </div>
              <ol>
                <li>
                  <span class="elv-report-transaction-drawer-journal-item-entry__direction title" />
                  <p
                    class="elv-report-transaction-drawer-journal-item-entry__account title"
                    :class="{ 'has-match': entryHasReconciliationMatch(index) }"
                  >
                    {{ t('common.account') }}
                  </p>
                  <div class="elv-report-transaction-drawer-journal-item-entry__debit title">
                    {{ t('common.debit') }}
                  </div>
                  <div class="elv-report-transaction-drawer-journal-item-entry__credit title">
                    {{ t('common.credit') }}
                  </div>
                  <p
                    v-if="entryHasReconciliationMatch(index)"
                    class="elv-report-transaction-drawer-journal-item-entry__match title"
                  />
                </li>
                <li
                  v-for="item in journalActivity?.entryList"
                  :key="item?.journalEntryId"
                  :class="{ 'is-matched': item?.matched }"
                >
                  <span class="elv-report-transaction-drawer-journal-item-entry__direction">{{
                    item?.balanceType
                  }}</span>
                  <div
                    class="elv-report-transaction-drawer-journal-item-entry__account"
                    :class="{ 'has-match': entryHasReconciliationMatch(index) }"
                  >
                    {{ item?.chartOfAccount?.name }}
                    <div
                      v-if="item?.auxiliaryValueList?.length"
                      class="elv-report-transaction-drawer-journal-item-entry__auxiliary-list"
                    >
                      <template v-for="auxiliaryValue in item.auxiliaryValueList" :key="auxiliaryValue.auxiliaryTypeId">
                        <div
                          v-if="getAuxiliaryValue(auxiliaryValue)"
                          class="elv-report-transaction-drawer-journal-item-entry__account-auxiliary"
                        >
                          {{ getAuxiliaryValue(auxiliaryValue) }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <p class="elv-report-transaction-drawer-journal-item-entry__debit">
                    <template v-if="item?.balanceType === 'Dr'">
                      <p
                        :title="`${fieldValueFormat(
                          item?.amountFC,
                          {
                            price: true,
                            keepPoint: true,
                            symbol: `${
                              entityDetail?.underlyingCurrency?.sign ||
                              `${entityDetail?.underlyingCurrency?.showSymbol} `
                            }`
                          },
                          'NUMBER'
                        )}`"
                      >
                        {{
                          formatNumberToSignificantDigits(
                            item?.amountFC,
                            2,
                            `${
                              entityDetail?.underlyingCurrency?.sign ||
                              `${entityDetail?.underlyingCurrency?.showSymbol} `
                            }`
                          )
                        }}
                      </p>
                      <span
                        :title="`${formatNumber(item?.amount, 9)} ${
                          item?.underlyingCurrency?.showSymbol ?? item?.currency
                        }`"
                        >{{ formatNumberToSignificantDigits(item?.amount, 2, '', false) }}
                        {{ item?.underlyingCurrency?.showSymbol ?? item?.currency }}</span
                      >
                    </template>
                    <template v-else>-</template>
                  </p>
                  <p class="elv-report-transaction-drawer-journal-item-entry__credit">
                    <template v-if="item?.balanceType === 'Cr'">
                      <p
                        :title="`${fieldValueFormat(
                          item?.amountFC,
                          {
                            price: true,
                            keepPoint: true,
                            symbol: `${
                              entityDetail?.underlyingCurrency?.sign ||
                              `${entityDetail?.underlyingCurrency?.showSymbol} `
                            }`
                          },
                          'NUMBER'
                        )}`"
                      >
                        {{
                          formatNumberToSignificantDigits(
                            item?.amountFC,
                            2,
                            `${
                              entityDetail?.underlyingCurrency?.sign ||
                              `${entityDetail?.underlyingCurrency?.showSymbol} `
                            }`
                          )
                        }}
                      </p>
                      <span
                        :title="`${formatNumber(item?.amount, 9)} ${
                          item?.underlyingCurrency?.showSymbol ?? item?.currency
                        }`"
                        >{{ formatNumberToSignificantDigits(item?.amount, 2, '', false) }}
                        {{ item?.underlyingCurrency?.showSymbol ?? item?.currency }}</span
                      >
                    </template>
                    <template v-else>-</template>
                  </p>
                  <p
                    v-if="entryHasReconciliationMatch(index)"
                    class="elv-report-transaction-drawer-journal-item-entry__match"
                  >
                    <el-tooltip v-if="item?.matched" effect="dark" placement="top" :show-after="500">
                      <SvgIcon
                        name="Checkmark"
                        width="16"
                        height="16"
                        fill="#41780C"
                        @click="onOpenMatchDetail(transactionDetail?.reconciliationMatch?.reconciliationMatchId)"
                      />
                      <template #content>
                        <div>{{ t('report.matched') }}</div>
                      </template>
                    </el-tooltip>
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <ul
        v-if="
          !props.isBatch &&
          currentEventData?.cryptoPayments &&
          transactionDetail?.costing?.capitalGainLoss &&
          transactionDetail?.costing?.costBasis
        "
        class="elv-report-transaction-drawer-loss"
      >
        <p class="elv-report-transaction-drawer-loss__title">{{ t('report.capitalGainORLoss') }}</p>
        <li>
          <p class="elv-report-transaction-drawer-loss__label">{{ t('report.marketValue') }}</p>
          <p class="elv-report-transaction-drawer-loss__value">
            <span>{{ formatNumber(props.currentData?.amount, 2) }}{{ props.currentData?.currency }}</span
            >{{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            }}{{ amountValue }}
          </p>
        </li>
        <!-- <li>
          <p class="elv-report-transaction-drawer-loss__label">Fee Market Value</p>
          <p class="elv-report-transaction-drawer-loss__value"><span>0.0029ETH</span>$5.21</p>
        </li> -->
        <li>
          <p class="elv-report-transaction-drawer-loss__label">{{ t('report.Cost basis') }}</p>
          <p class="elv-report-transaction-drawer-loss__value">
            <template v-if="costBasisValue">
              {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              }}{{ costBasisValue }}
            </template>
            <template v-else> - </template>
          </p>
        </li>
        <!-- <li>
          <p class="elv-report-transaction-drawer-loss__label">Fee Cost Basis</p>
          <p class="elv-report-transaction-drawer-loss__value">$1.49</p>
        </li> -->
        <li>
          <p class="elv-report-transaction-drawer-loss__label">{{ t('report.capitalGainORLoss') }}</p>
          <p class="elv-report-transaction-drawer-loss__value">
            <template v-if="transactionDetail?.costing?.capitalGainLoss"
              >{{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              }}{{ formatNumber(transactionDetail?.costing?.capitalGainLoss, 2) }}
            </template>
            <template v-else> -</template>
          </p>
        </li>
      </ul>

      <div
        v-if="transactionDetail?.inputs?.length && transactionDetail?.direction === 'IN'"
        class="elv-report-transaction-drawer-inputs"
      >
        <div class="elv-report-transaction-drawer-inputs-title">
          {{ t('label.inputs') }}&nbsp;({{ transactionDetail?.inputs?.length ?? 0 }})
        </div>
        <el-input
          v-model="searchQuery"
          class="elv-report-transaction-drawer-inputs-search"
          :placeholder="t('placeholder.search')"
          :prefix-icon="SearchIcon"
          @input="onDebounceSearchAddress"
        />

        <ul class="elv-report-transaction-drawer-inputs-list">
          <li v-for="(item, index) in inputList" :key="index" class="elv-report-transaction-drawer-inputs-item">
            <div class="elv-report-transaction-drawer-inputs-item__left">
              <span class="elv-report-transaction-drawer-inputs-item__index">{{ index + 1 }}</span>
              <div class="elv-report-transaction-drawer-inputs-item__info">
                <!-- <p v-if="item?.counterparty?.name" class="elv-report-transaction-drawer-inputs-item__info-counterparty">
                  {{ item?.counterparty?.name }}
                </p> -->
                <div class="elv-report-transaction-drawer-inputs-item__info-address">
                  <!-- :class="{ 'has-counterparty': item?.counterparty?.name }" -->
                  <p>{{ middleEllipsis(item?.address) }}</p>
                  <SvgIcon name="sources-copy" width="10" height="10" @click="onClickedCopy(item?.address)" />
                </div>
              </div>
            </div>
            <div class="elv-report-transaction-drawer-inputs-item__right">
              <p>
                {{ formatNumber(item?.amount, 20) }}
                {{ props.currentData?.underlyingCurrency?.showSymbol ?? props.currentData?.currency }}
              </p>
              <span
                >({{
                  formatNumberToSignificantDigits(
                    item?.amountFC,
                    2,
                    `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  )
                }})</span
              >
            </div>
          </li>
        </ul>
        <div
          v-if="inputList.length < transactionDetail?.inputs?.length && !searchQuery"
          class="elv-report-transaction-drawer-inputs-more"
        >
          <div @click="onLoadMoreAddress">
            <SvgIcon name="events-arrow" width="10" height="10" fill="#838D95" />{{ t('button.loadMore') }}
          </div>
        </div>
      </div>
      <Memo
        v-if="!props.isBatch"
        ref="uploadMemoRef"
        :current-data="transactionDetail ?? {}"
        @onMemoChange="onMemoChange"
        @onAttachmentsChange="onAttachmentsChange"
      />
    </el-scrollbar>
  </el-drawer>

  <SelectCounterpartyDialog
    ref="selectCounterpartyDialogRef"
    type="detail"
    :current-data="transactionDetail"
    @onReset="updateDetail"
  />

  <MatchDetailDialog
    ref="matchDetailDialogRef"
    :reconciliation-match-id="reconciliationMatchId"
    :current-data="{}"
    @onResetList="updateDetail"
  />
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import {
  formatNumber,
  middleEllipsis,
  fieldValueFormat,
  formatInputAddress,
  calculateUtcOffset,
  formatNumberToSignificantDigits
} from '@/lib/utils'
import utc from 'dayjs/plugin/utc'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import timezone from 'dayjs/plugin/timezone'
import { transformI18n } from '@/i18n/index'
import Memo from '../../components/Memo.vue'
import { transactionType } from '@/config/index'
import TransactionsApi from '@/api/TransactionsApi'
import defaultImg from '@/assets/img/default-logo.png'
import SearchIcon from '../../components/SearchIcon.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { TransactionDetailType } from '#/TransactionsTypes'
import { chainBrowserMap, reportEventList } from '@/config/reports'
import { map, find, flatMap, isEmpty, debounce, filter } from 'lodash-es'
import SelectCounterpartyDialog from '../../components/SelectCounterpartyDialog.vue'
import MatchDetailDialog from '@/pages/Financials/Project/components/MatchDetailDialog.vue'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'
import BorrowCell from '@/components/Reports/Cell/BorrowCell.vue'

const props = defineProps({
  currentData: {
    type: Object,
    required: true
  },
  isBatch: {
    type: Boolean,
    default: false
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
const { toClipboard } = useClipboard()

const uploadMemoRef = ref()
const eventDropdownRef = ref()
const matchDetailDialogRef = ref()
const selectCounterpartyDialogRef = ref()
const loading = ref(false)
const counterpartyId = ref('')
const reconciliationMatchId = ref('')
const isShowEditDrawer = ref(false)
const transactionDetail = ref({} as TransactionDetailType)
const searchQuery = ref('')
const inputsParams = reactive({
  page: 1,
  limit: 10
})
const transactionDetailIcon = [
  'polygon',
  'arbitrum',
  'avalanche',
  'optimism',
  'ethereum',
  'bsc',
  'astar',
  'tron',
  'bitkub'
]

const emit = defineEmits(['onResetList'])

const isFeeType = computed(() => {
  return [
    'CHAIN_TRANSACTION_FEE',
    'BANK_FEE',
    'EXCHANGE_FEE',
    'PAYMENT_FEE',
    'CUSTODY_FEE',
    'EXCHANGE_FUNDING_FEE'
  ].includes(props.currentData?.type)
})

const isFXType = computed(() => {
  const type = props.currentData?.type
  return ['BANK_INCOMING_FX', 'BANK_OUTGOING_FX', 'PAYMENT_OUTGOING_FX', 'PAYMENT_INCOMING_FX'].includes(type)
})

const isExchangeTrade = computed(() => {
  const type = props.currentData?.type
  return props.currentData?.type === 'EXCHANGE_TRADE_OUT' || type === 'EXCHANGE_TRADE_IN'
})

const hasInputs = computed(() => {
  return !!props.currentData?.inputs?.length && !isFeeType.value
})

const direction = computed(() => {
  let value = ''
  const transactionTypeList = flatMap(transactionType, 'list')
  transactionTypeList.forEach((item) => {
    if (item.value === props.currentData?.type) {
      value = item.label
    }
  })
  return value
})

const getAuxiliaryValue = (item: any) => {
  if (item.auxiliaryType.type === 'ENTITY_ACCOUNT') {
    return item?.entityAccount?.name
  }

  if (item.auxiliaryType.type === 'COUNTERPARTY') {
    return item?.counterparty?.name
  }

  if (item.auxiliaryType.type === 'ITEM') {
    return item.auxiliaryItem?.value
  }

  return null
}

const inputList = computed(() => {
  if (transactionDetail.value?.inputs?.length) {
    if (!searchQuery.value) {
      return transactionDetail.value?.inputs.slice(0, inputsParams.page * inputsParams.limit)
    }
    const query = searchQuery.value.toLowerCase()
    return filter(transactionDetail.value?.inputs, (input) => {
      return input?.address?.toLowerCase()?.includes(query) || input?.address?.toLowerCase() === query
    })
  }
  return []
})

const entryHasReconciliationMatch = computed(() => {
  return (index: number) => {
    return transactionDetail.value?.journalActivityList?.[index]?.entryList?.some((entry: any) => entry?.matched)
  }
})

const platformType = computed(() => {
  let text = ''
  switch (props.currentData?.platformType) {
    case 'CHAIN':
      text = t('common.onChain')
      break
    case 'EXCHANGE':
      text = t('common.exchange')
      break
    case 'BANK':
      text = t('common.bank')
      break
    default:
      text = t('common.custody')
      break
  }
  return text
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const amountValue = computed(() => {
  return props.currentData?.amountFC ? formatNumber(props.currentData?.amountFC, 2) : props.currentData?.amountFC
})
const costBasisValue = computed(() => {
  return transactionDetail.value?.costing?.costBasis
    ? formatNumber(transactionDetail.value?.costing?.costBasis, 2)
    : transactionDetail.value?.costing?.costBasis
})

const SourceLogo = computed(() => {
  if (props.currentData?.source?.sourceName === 'everPay' && props.currentData?.source?.type === 'API')
    return { logo: 'https://static.elven.com/project/everpay.png', name: 'everPay' }
  if (!props.currentData?.source) return { logo: defaultImg, name: '-' }
  // if (props.currentData?.source.type === 'CSV') return 'csv'
  return props.currentData?.source?.platform?.logo ? props.currentData?.source?.platform : 'csv'
})

const currentEventData = computed(() => {
  let data: any = {}
  if (eventDropdownRef.value?.currentCheckedEvent?.categoryName) {
    data = eventDropdownRef.value?.currentCheckedEvent
  } else {
    data = props.currentData.categoryNo ? find(reportEventList, { categoryNo: props.currentData.categoryNo }) : {}
  }
  return data
})

const currentFromLogo = computed(() => {
  const sourceLogo =
    // eslint-disable-next-line no-nested-ternary
    SourceLogo.value === 'csv'
      ? props.currentData?.source?.csvFile?.templateType !== 'BANK'
        ? 'csv'
        : 'https://static.elven.com/bank/Other%20Bank.png'
      : !props.currentData?.contactPlatform?.logo && props.currentData?.contactIdentity
        ? ''
        : SourceLogo.value?.logo
  return props.currentData?.direction === 'OUT'
    ? props.currentData?.platform?.logo
    : (props.currentData?.contact?.platform?.logo ?? sourceLogo)
})

const currentToLogo = computed(() => {
  const sourceLogo =
    // eslint-disable-next-line no-nested-ternary
    SourceLogo.value === 'csv'
      ? props.currentData?.source?.csvFile?.templateType !== 'BANK'
        ? 'csv'
        : 'https://static.elven.com/bank/Other%20Bank.png'
      : !props.currentData?.contactPlatform?.logo && props.currentData?.contactIdentity
        ? ''
        : SourceLogo.value?.logo
  return props.currentData?.direction === 'IN'
    ? props.currentData?.platform?.logo
    : (props.currentData?.contact?.platform?.logo ?? sourceLogo)
})

const onOpenMatchDetail = (id: string) => {
  reconciliationMatchId.value = id
  matchDetailDialogRef.value?.onCheckMatchDetailDialog()
}

const onCheckDrawerStatus = () => {
  isShowEditDrawer.value = !isShowEditDrawer.value
  searchQuery.value = ''
  inputsParams.page = 1
}

const onLoadMoreAddress = () => {
  inputsParams.page += 1
}

const onSearchAddress = (val: string) => {
  console.log('🚀 ~ onSearchAddress ~ val:', val)
  // searchQuery.value = val
}

const onDebounceSearchAddress = debounce(onSearchAddress, 300)

defineExpose({ onCheckDrawerStatus })

const getProvider = (row: any) => {
  if (row?.entityAccount?.verifyProvider) {
    return `(${row?.entityAccount?.verifyProvider?.name})`
  }
  return ''
}

const onMemoChange = async (memo: string) => {
  try {
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.transaction?.update
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    loading.value = true
    const params = {
      memo
    }
    await TransactionsApi.editTransactionDetail(entityId.value, props.currentData?.transactionId, params)
    ElMessage.success(t('message.editSuccess'))
  } catch (error: any) {
    ElMessage.error(error.message)
    loading.value = false
    console.error(error)
  } finally {
    loading.value = false
  }
}

const onAttachmentsChange = async (fileList: any[]) => {
  try {
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.transaction?.update
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    loading.value = true
    const params = {
      transactionAttachmentIds: map(fileList, 'transactionAttachmentId') ?? []
    }
    await TransactionsApi.editTransactionDetail(entityId.value, props.currentData?.transactionId, params)
    ElMessage.success(t('message.editSuccess'))
  } catch (error: any) {
    ElMessage.error(error.message)
    loading.value = false
    console.error(error)
  } finally {
    loading.value = false
  }
}

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

const onOpenBrowser = () => {
  const browserLink = chainBrowserMap[props.currentData?.source?.platform?.slug] ?? 'https://etherscan.io/tx'
  window.open(
    // eslint-disable-next-line no-script-url
    'javascript:window.name;',
    // eslint-disable-next-line no-useless-escape, no-useless-concat
    '<script>location.replace("' + `${browserLink}/${props.currentData?.txHash}` + '")<\/script>'
  )
}

const onEditCounterparty = () => {
  selectCounterpartyDialogRef.value?.onCheckSelectCounterpartyDialog()
}

const fetchTransactionDetail = async () => {
  try {
    loading.value = true
    const { data } = await TransactionsApi.getTransactionDetail(
      props.currentData?.entityId,
      props.currentData?.transactionId
    )
    transactionDetail.value = data
    counterpartyId.value = ''
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const updateDetail = async () => {
  await fetchTransactionDetail()
  emit('onResetList')
}

watch(
  [() => isShowEditDrawer.value, () => props.currentData],
  () => {
    if (isShowEditDrawer.value && !isEmpty(props.currentData)) {
      nextTick(async () => {
        if (props.currentData?.transactionId) {
          await fetchTransactionDetail()
        }
      })
    }
  },
  {
    immediate: true,
    flush: 'post'
  }
)
</script>

<style lang="scss" scoped>
.elv-report-transaction-drawer {
  .elv-report-transaction-drawer-header__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
    margin: 0;
  }

  .elv-report-transaction-drawer-header__close {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-report-transaction-drawer {
  .el-drawer__header {
    padding: 26px 0px 18px;
    margin: 0 24px;
    border-bottom: 1px solid #edf0f3;
    height: 64px;
    box-sizing: border-box;
  }

  .el-drawer__body {
    padding: 16px 0px;
    margin-bottom: 16px;
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 24px;
  }

  .el-scrollbar__view {
    padding: 0 24px;
  }

  .elv-report-transaction-drawer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 10px;
  }

  .elv-report-transaction-drawer-info {
    margin-bottom: 16px;

    .elv-report-transaction-drawer-info__title {
      display: flex;
      align-items: center;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #838d95;

      span {
        width: 1px;
        height: 12px;
        display: block;
        background: #dde1e6;
        margin: 0 6px;
      }
    }

    .elv-report-transaction-drawer-info__total {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      color: #1e2024;
      position: relative;

      .elv-report-transaction-drawer-info__holding {
        position: absolute;
        bottom: 0px;
        right: 0px;
        display: flex;
        flex-direction: column;
        color: #aaafb6;
        font-family: 'Barlow';
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        p {
          color: #1e2024;
          font-family: 'Barlow';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .elv-report-transaction-drawer-info__balance-verify {
      display: flex;
      align-items: center;
      color: #838d95;
      font-family: 'Plus Jakarta Sans';
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;

      svg,
      img {
        margin-right: 4px;
      }

      img {
        width: 12px;
        height: 12px;
        display: block;
      }

      .waiting {
        fill: #feb69c;
      }

      &.is-fail {
        color: #eb3a30;
      }

      &.is-success {
        color: #83ae00;
      }
    }

    ul {
      margin: 16px 0;
      border-top: 1px solid #edf0f3;
      display: flex;
      flex-wrap: wrap;

      li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50%;
        height: 54px;
        box-sizing: border-box;
        border-bottom: 1px solid #edf0f3;

        &:nth-child(2n) {
          padding-left: 8px;
          border-left: 1px solid #edf0f3;
        }

        &:nth-child(1),
        &:nth-child(2) {
          height: 60px;
        }

        span {
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 11px;
          line-height: 14px;
          color: #838d95;
        }
      }

      .elv-report-transaction-drawer-info__source {
        p {
          display: flex;
          align-items: center;
          margin-top: 2px;

          img {
            width: 14px;
            height: 14px;
            display: block;
          }

          span {
            margin-left: 5px;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
          }
        }
      }

      .elv-report-transaction-drawer-info__base_asset {
        .elv-financials-cell-currency-main {
          margin-top: 4px;

          img {
            width: 14px;
            height: auto;
          }
        }
      }

      .elv-report-transaction-drawer-info__amount-type {
        .elv-financials-cell-topBottom-main {
          justify-content: flex-start;

          span:first-child {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
          }

          .elv-financials-cell-topBottom-conent {
            margin: 0px;

            .elv-financials-cell-topBottom-content__top {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              span {
                font-size: 10px;
                font-weight: 400;

                &:first-child {
                  font-size: 12px;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }

      .elv-report-transaction-drawer-transaction__detail {
        > div {
          display: flex;
          align-items: center;
          margin-top: 4px;
        }

        img {
          width: 14px;
          height: 14px;
          display: block;
          border-radius: 50%;
        }

        p {
          margin-left: 5px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #1e2024;
          margin-right: 4px;
        }

        svg {
          cursor: pointer;
        }
      }

      .elv-report-transaction-drawer-info__dateTime,
      .elv-report-transaction-drawer-info__amount {
        p {
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #0e0f11;
          margin-top: 2px;
        }
      }

      .elv-report-transaction-drawer-info__from {
        .elv-report-transaction-drawer-info__from-title {
          display: flex;
          align-items: center;

          svg {
            margin-left: 6px;
            cursor: pointer;
            fill: #aaafb6;

            &:hover {
              fill: #1e2024;
            }
          }
        }

        .elv-report-transaction-drawer-info__from-account {
          display: flex;
          align-items: center;
          margin-top: 2px;
          padding: 0px 12px 0px 6px;
          height: 23px;
          box-sizing: border-box;
          border-radius: 12px;
          border: 1px solid #edf0f3;
          background: #f9fafb;

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
            max-width: 98px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .elv-report-transaction-drawer-info__you {
            display: flex;
            padding: 1px 4px;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            background: #edf0f3;
            color: #1753eb;
            font-family: 'Barlow';
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 4px;
          }

          .elv-report-transaction-drawer-info__trade {
            display: flex;
            padding: 1px 4px;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            background: #edf0f3;
            color: #ff754e;
            font-family: 'Barlow';
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 4px;
          }

          img {
            width: 14px;
            height: 14px;
            display: block;
            margin-right: 4px;
            border-radius: 50%;
          }

          .from_logo {
            margin-right: 4px;
          }

          .elv-report-transaction-drawer-info__from-address {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;

            svg {
              cursor: pointer;
            }
          }
        }

        .elv-report-transaction-drawer-info__from-contact {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px 12px 0px 6px;
          height: 23px;
          box-sizing: border-box;
          border-radius: 12px;
          border: 1px solid #edf0f3;
          background: #f9fafb;
          margin-top: 2px;

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
          }

          img {
            width: 14px;
            height: 14px;
            margin-right: 4px;
          }

          p {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;
            padding-right: 8px;

            svg {
              margin-right: 2px;
            }
          }

          .elv-report-transaction-drawer-info__multi-addresses {
            .elv-report-transaction-drawer-info__fee {
              display: flex;
              padding: 1px 4px;
              justify-content: center;
              align-items: center;
              border-radius: 3px;
              background: #edf0f3;
              color: #ff754e;
              font-family: 'Barlow';
              font-size: 11px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin-left: 4px;
            }
          }
        }

        .elv-report-transaction-drawer-info__to-contact {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px 12px 0px 6px;
          height: 23px;
          box-sizing: border-box;
          border-radius: 12px;
          border: 1px solid #edf0f3;
          background: #f9fafb;
          margin-top: 2px;

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
            max-width: 98px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          p {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;

            svg {
              margin-right: 2px;
            }
          }

          .elv-report-transaction-drawer-info__to-address {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #0e0f11;

            svg {
              cursor: pointer;
            }
          }
        }

        .elv-report-transaction-drawer-info__from-address {
          margin-top: 2px;
          display: flex;
          align-items: center;
          font-family: 'Barlow';
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #838d95;

          svg {
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }

      .elv-report-transaction-drawer-info__to {
        .elv-report-transaction-drawer-info__to-title {
          display: flex;
          align-items: center;

          svg {
            margin-left: 6px;
            cursor: pointer;
            fill: #aaafb6;

            &:hover {
              fill: #1e2024;
            }
          }
        }

        .elv-report-transaction-drawer-info__to-contact {
          width: fit-content;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 2px;
          padding: 0px 12px 0px 6px;
          height: 23px;
          box-sizing: border-box;
          border-radius: 12px;
          border: 1px solid #edf0f3;
          background: #f9fafb;

          img {
            width: 14px;
            height: 14px;
            display: block;
            margin-right: 4px;
          }

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
            max-width: 98px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .elv-report-transaction-drawer-info__multi-addresses {
            span {
              max-width: fit-content;
            }

            .elv-report-transaction-drawer-info__fee {
              display: flex;
              padding: 1px 4px;
              justify-content: center;
              align-items: center;
              border-radius: 3px;
              background: #edf0f3;
              color: #ff754e;
              font-family: 'Barlow';
              font-size: 11px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin-left: 4px;
            }
          }
        }

        .elv-report-transaction-drawer-info__to-account {
          display: flex;
          align-items: center;
          margin-top: 2px;
          padding: 0px 12px 0px 6px;
          height: 23px;
          box-sizing: border-box;
          border-radius: 12px;
          border: 1px solid #edf0f3;
          background: #f9fafb;

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
            max-width: 98px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .elv-report-transaction-drawer-info__fee {
            display: flex;
            padding: 1px 4px;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            background: #edf0f3;
            color: #ff754e;
            font-family: 'Barlow';
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 4px;
          }

          .elv-report-transaction-drawer-info__you {
            display: flex;
            padding: 1px 4px;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            background: #edf0f3;
            color: #1753eb;
            font-family: 'Barlow';
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 4px;
          }

          img {
            width: 14px;
            height: 14px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }

          .to_logo {
            margin-right: 4px;
          }

          .elv-report-transaction-drawer-info__to-address {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #0e0f11;

            svg {
              cursor: pointer;
            }
          }
        }

        p {
          margin-top: 2px;
          display: flex;
          align-items: center;
          font-family: 'Barlow';
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #838d95;

          svg {
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .elv-report-transaction-drawer-journal {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f9fafb;

    .elv-report-transaction-drawer-journal-title {
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }

    .elv-report-transaction-drawer-journal-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .elv-report-transaction-drawer-journal-item__type {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding: 10px 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid #dde1e6;
      background: #fff;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
      margin-bottom: 8px;

      p {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
      }

      span {
        color: #838d95;
        font-family: 'Barlow';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
      }
    }

    ol {
      background: #ffffff;
      border: 1px solid #ced7e0;
      border-radius: 4px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #0e0f11;

      li {
        min-height: 40px;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: stretch; /* 子元素的高度将与父元素相匹配 */
        justify-content: center;
        box-sizing: border-box;
        border-top: 1px solid #edf0f3;

        &:first-child {
          min-height: 28px;
          border-top: 0px;
        }

        &.is-matched {
          background-color: #f1f8ea;
        }
      }

      .elv-report-transaction-drawer-journal-item-entry__direction {
        min-height: 40px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.title {
          min-height: 28px;
        }
      }

      .elv-report-transaction-drawer-journal-item-entry__account {
        width: 156px;
        min-height: 40px;
        height: 100%;
        padding: 5px 10px;
        box-sizing: border-box;
        border-left: 1px solid #edf0f3;
        border-right: 1px solid #edf0f3;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 5px;

        &.title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #0e0f11;
          min-height: 28px;
        }

        &.has-match {
          width: 129px;
        }

        .elv-report-transaction-drawer-journal-item-entry__auxiliary-list {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          gap: 4px;
        }

        .elv-report-transaction-drawer-journal-item-entry__account-auxiliary {
          box-sizing: border-box;
          height: 19px;
          padding: 0px 9px;
          border-radius: 16px;
          max-width: 136px;
          border: 1px solid #dde1e6;
          background: #f9fafb;
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .elv-report-transaction-drawer-journal-item-entry__match {
        width: 28px;
        max-width: 28px;
        min-height: 40px;
        flex: 1;
        box-sizing: border-box;
        border-left: 1px solid #edf0f3;
        display: flex;
        align-items: center;
        justify-content: center;

        &.title {
          min-height: 28px;
        }

        svg {
          cursor: pointer;
        }
      }

      .elv-report-transaction-drawer-journal-item-entry__debit {
        border-right: 1px solid #edf0f3;
      }

      .elv-report-transaction-drawer-journal-item-entry__debit,
      .elv-report-transaction-drawer-journal-item-entry__credit {
        width: 75px;
        min-height: 40px;
        padding-right: 10px;
        flex: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        overflow: hidden;
        word-break: break-word;
        text-align: right;

        span {
          color: #838d95;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          white-space: nowrap;
          overflow: hidden;
        }

        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #0e0f11;
          min-height: 28px;
        }
      }
    }
  }

  .elv-report-transaction-drawer-loss {
    box-sizing: border-box;
    padding: 12px 8px;
    width: 100%;
    background: #f9fafb;
    border-radius: 5px;
    margin-bottom: 16px;

    .elv-report-transaction-drawer-loss__title {
      width: 100%;
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #0e0f11;
      padding-bottom: 10px;
      border-bottom: 1px solid #dde1e6;
      margin-bottom: 10px;
    }

    li {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .elv-report-transaction-drawer-loss__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #1e2024;
      }

      .elv-report-transaction-drawer-loss__value {
        font-family: 'Barlow';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;

        span {
          padding-right: 6px;
          border-right: 1px solid #dde1e6;
          color: #838d95;
          margin-right: 5px;
        }
      }
    }
  }

  .elv-report-transaction-drawer-inputs {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f9fafb;
    margin-bottom: 16px;

    .elv-report-transaction-drawer-inputs-title {
      padding: 6px 0px 14px;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      border-bottom: 1px solid #edf0f3;
      margin-bottom: 8px;
    }

    .elv-report-transaction-drawer-inputs-search {
      width: 100%;

      .el-input__wrapper {
        border-radius: 24px;
        border: 1px solid #edf0f3;
        box-shadow: none;
      }
    }

    .elv-report-transaction-drawer-inputs-list {
      .elv-report-transaction-drawer-inputs-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid #edf0f3;

        .elv-report-transaction-drawer-inputs-item__left {
          display: flex;
          align-items: center;
          gap: 8px;

          .elv-report-transaction-drawer-inputs-item__index {
            width: 16px;
            color: #aaafb6;
            font-family: 'Barlow';
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 16.5px;
          }

          .elv-report-transaction-drawer-inputs-item__info {
            display: flex;
            flex-direction: column;
          }

          .elv-report-transaction-drawer-inputs-item__info-counterparty {
            color: #1e2024;
            font-family: 'Plus Jakarta Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .elv-report-transaction-drawer-inputs-item__info-address {
            display: flex;
            align-items: center;

            p {
              color: #1e2024;
              font-family: 'Barlow';
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 19.5px;
            }

            svg {
              width: 10px;
              height: 10px;
              cursor: pointer;
              margin-left: 8px;
            }

            &.has-counterparty {
              p {
                color: #aaafb6;
                font-size: 9px;
                font-weight: 400;
                line-height: normal;
              }

              svg {
                width: 8px;
                height: 8px;
                margin-left: 4px;
              }
            }
          }
        }

        .elv-report-transaction-drawer-inputs-item__right {
          display: flex;
          align-items: flex-end;
          flex-direction: column;

          p {
            color: #1e2024;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          span {
            color: #aaafb6;
            font-family: 'Barlow';
            font-size: 9px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    .elv-report-transaction-drawer-inputs-more {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;

      div {
        display: flex;
        height: 30px;
        padding: 0px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        border-radius: 22px;
        background: #fff;

        svg {
          transform: rotate(90deg);
        }

        &:hover {
          background-color: #edf0f3;
          color: #0e0f11;

          svg {
            fill: #636b75;
          }
        }
      }
    }
  }
}

.el-popper.elv-transactions-drawer-event-popper {
  .elv-transactions-drawer-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}
</style>
